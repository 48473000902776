<template>
  <div>
  
    <div v-if="showBanner" slot="adBanner" class="ad-banner">
      <h4>{{ $t("ads.10PercentOff") }}</h4>
    </div>

    <layout-vertical>
      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </template>

      <!-- <app-customizer
        v-if="showCustomizer"
        slot="customizer"
      /> -->
    </layout-vertical>
  </div>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import store from "@/store";
import GenericLoading from "@/components/GenericLoading.vue";
import Navbar from "../components/Navbar.vue";





export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    GenericLoading,
},
  data() {
    return {
     
    };
  },
  created() {
    this.$nextTick(() => {
          this.isLoading = false
        })
  },
  mounted() {
console.debug(this.dashboardInfo)
    if (this.dashboardInfo !=  null && this.dashboardInfo.validSubscription) {
        this.showBanner = false
      }
    this.showBanner = true
  },
  computed: {
    dashboardInfo() {
      return store.getters["app/dashboard"];
    },
    showBanner() {
      if (this.dashboardInfo !== null && this.dashboardInfo.validSubscription) {
        return false
      }
      return true;
    },
  },
};
</script>

<style>
.ad-banner {
  background: #ef6d3e !important;
  text-align: center;
  padding: 10px 0;
}

.ad-banner h4 {
  color: #ffffff;
  margin-bottom: 0;
}
</style>